
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../css/housing.css'; 
import Navbar from './nav';
import Footer from './foot';

function CommercialList() {
  const [commercials, setCommercials] = useState([]);

  useEffect(() => {
    const fetchCommercials = async () => {
      try {
        const response = await axios.get('https://angry-equinox-amusement.glitch.me/commercials');
        setCommercials(response.data);
      } catch (error) {
        console.error('Error fetching commercials:', error);
      }
    };

    fetchCommercials();
  }, []);

  return (
    <div>
      <Navbar/>
      <div className="product-list">
        <h1 className="product-list-title">COMMERCIAL</h1>
        <ul className="product-items">
          {commercials.map((commercial, index) => (
            <CommercialItem key={index} commercial={commercial} />
          ))}
        </ul>
      </div>
      <Footer/>
    </div>
  );
}

const CommercialItem = ({ commercial }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <li className="product-item">
      <div className="product-content">
        <div className="product-gallery" onClick={toggleExpanded}>
          <img
            src={commercial.gallery[0]}
            alt={commercial.name}
            className="product-image main-image"
          />
          <div className="product-name-overlay">
            {commercial.name}
          </div>
        </div>
        {isExpanded && (
          <div className="product-details">
            <h2 className="product-name">{commercial.name}</h2>
            <p className="product-description">{commercial.description}</p>
            {commercial.gallery.length > 1 && (
              <div className="product-gallery">
                {commercial.gallery.slice(1).map((url, idx) => (
                  <img
                    key={idx}
                    src={url}
                    alt={`Commercial ${idx}`}
                    className="product-image"
                  />
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </li>
  );
};

export default CommercialList;
