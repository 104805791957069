
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../css/housing.css'; 
import Navbar from './nav';
import Footer from './foot';

function OfficeList() {
  const [offices, setOffices] = useState([]);

  useEffect(() => {
    const fetchOffices = async () => {
      try {
        const response = await axios.get('https://angry-equinox-amusement.glitch.me/offices');
        setOffices(response.data);
      } catch (error) {
        console.error('Error fetching offices:', error);
      }
    };

    fetchOffices();
  }, []);

  return (
    <div>
      <Navbar/>
      <div className="product-list">
        <h1 className="product-list-title">OFFICES</h1>
        <ul className="product-items">
          {offices.map((office, index) => (
            <OfficeItem key={index} office={office} />
          ))}
        </ul>
      </div>
      <Footer/>
    </div>
  );
}

const OfficeItem = ({ office }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <li className="product-item">
      <div className="product-content">
        <div className="product-gallery" onClick={toggleExpanded}>
          <img
            src={office.gallery[0]}
            alt={office.name}
            className="product-image main-image"
          />
          <div className="product-name-overlay">
            {office.name}
          </div>
        </div>
        {isExpanded && (
          <div className="product-details">
            <h2 className="product-name">{office.name}</h2>
            <p className="product-description">{office.description}</p>
            {office.gallery.length > 1 && (
              <div className="product-gallery">
                {office.gallery.slice(1).map((url, idx) => (
                  <img
                    key={idx}
                    src={url}
                    alt={`Office ${idx}`}
                    className="product-image"
                  />
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </li>
  );
};

export default OfficeList;
