import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/home.css';
import Navbar from './nav';
import Footer from './foot';
import image1 from '../images/img1.jpg';
import image2 from '../images/img2.jpg';
import image3 from '../images/img3.jpg';
import image4 from '../images/img4.jpg';
import image5 from '../images/img5.jpg';


const LuxusInterior = () => {
  useEffect(() => {
    const images = document.querySelectorAll('.image-container img');
    let currentIndex = 0;
    let intervalId;

    function showNextImage() {
      images[currentIndex].classList.remove('active');
      currentIndex = (currentIndex + 1) % images.length;
      images[currentIndex].classList.add('active');
    }

    function updateCarousel() {
      if (window.innerWidth > 768) {
        images.forEach(img => img.classList.remove('active'));
        images[currentIndex].classList.add('active');
        if (!intervalId) {
          intervalId = setInterval(showNextImage, 5000);
        }
      } else {
        images.forEach(img => img.classList.add('active'));
        if (intervalId) {
          clearInterval(intervalId);
          intervalId = null;
        }
      }
    }

    updateCarousel();
    window.addEventListener('resize', updateCarousel);

    return () => {
      window.removeEventListener('resize', updateCarousel);
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);

  return (
    <div>
      <Navbar/>
      
      <main className="image-container">
      <img loading="lazy" decoding="async" src={image1} alt="" />
      <img src={image2} alt="" />
      <img src={image3} alt="" />
      <img src={image4} alt="" />
      <img src={image5} alt="" />
      </main>

      <Footer />
    </div>
  );
};

export default LuxusInterior;
