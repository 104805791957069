import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './upImg.css';

function AddProduct() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const form = document.querySelector('form');
    const productName = document.getElementById('product-name');
    const productDescription = document.getElementById('product-description');
    const productImages = document.getElementById('product-images');
    const productType = document.getElementById('product-type');

    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);

      try {
        const urls = await uploadFiles(productImages.files);
        await addProduct({
          name: productName.value,
          description: productDescription.value,
          gallery: urls,
          type: productType.value,
        });
        toast.success('Product added successfully!');
        form.reset(); // Reset the form fields
        setLoading(false);
      } catch (error) {
        console.error('Error:', error);
        toast.error('Failed to add product. Please try again.');
        setLoading(false);
      }
    };

    form.addEventListener("submit", handleSubmit);

    return () => {
      form.removeEventListener("submit", handleSubmit);
    };
  }, []);

  const uploadFiles = async (files) => {
    if (files) {
      const CLOUD_NAME = "dg2iqmelk";
      const PRESET_NAME = "luxus-website";
      const FOLDER_NAME = "LUXUS";
      const urls = [];
      const api = `https://api.cloudinary.com/v1_1/${CLOUD_NAME}/image/upload`;

      for (const file of files) {
        const formData = new FormData();
        formData.append("upload_preset", PRESET_NAME);
        formData.append("folder", FOLDER_NAME);
        formData.append("file", file);

        try {
          const response = await axios.post(api, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          urls.push(response.data.secure_url);
        } catch (error) {
          console.error(error);
        }
      }
      return urls;
    }
  };

  const addProduct = async (product) => {
    try {
      let endpoint;
      switch (product.type) {
        case 'housing':
          endpoint = 'https://angry-equinox-amusement.glitch.me/products';
          break;
        case 'commercial':
          endpoint = 'https://angry-equinox-amusement.glitch.me/commercials';
          break;
        case 'office':
          endpoint = 'https://angry-equinox-amusement.glitch.me/offices';
          break;
        default:
          throw new Error('Invalid product type');
      }
      await axios.post(endpoint, product);
    } catch (error) {
      console.error('Error adding product:', error);
      throw error;
    }
  };
  

  const handleLogout = () => {
    sessionStorage.removeItem('isLoggedIn');
    navigate('/admin/login');
  };

  return (
    <div className='add-product-admin'>
      <h1>Add Product</h1>
      <button className="btn btn-secondary mb-3" onClick={handleLogout}>
        Logout
      </button>
      <form>
        <div className="form-group mb-3">
          <label>Nhập Tên</label>
          <input type='text' id='product-name' className="form-control" />
        </div>
        <div className="form-group mb-3">
          <label>Nhập giới thiệu</label>
          <textarea
            id="product-description"
            className="form-control"
            rows="5"
            placeholder="Enter the product description here..."
          ></textarea>
        </div>

        <div className="form-group mb-3">
          <label>Thêm ảnh</label>
          <input type='file' id='product-images' multiple className="form-control" />
        </div>
        <div className="form-group mb-3">
          <label>Chọn loại</label>
          <select id='product-type' className="form-control">
            <option value="housing">Housing</option>
            <option value="commercial">Commercial</option>
            <option value="office">Office</option>
          </select>
        </div>
        <div className="form-group">
          <button className="btn btn-primary" type="submit" disabled={loading}>
            {loading ? 'Adding...' : 'ADD'}
          </button>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
}

export default AddProduct;
