// src/components/ProductList.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../css/housing.css';
import Navbar from './nav';
import Footer from './foot';

function Housing() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('https://angry-equinox-amusement.glitch.me/products');
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  return (
    <div>
      <Navbar/>
      <div className="product-list">
        <h1 className="product-list-title">HOUSING</h1>
        <ul className="product-items">
          {products.map((product, index) => (
            <ProductItem key={index} product={product} />
          ))}
        </ul>
      </div>
      <Footer/>
    </div>
  );
}

const ProductItem = ({ product }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <li className="product-item">
      <div className="product-content">
        <div className="product-gallery" onClick={toggleExpanded}>
          <img
            src={product.gallery[0]}
            alt={product.name}
            className="product-image main-image"
          />
          <div className="product-name-overlay">
            {product.name}
          </div>
        </div>
        {isExpanded && (
          <div className="product-details">
            <h2 className="product-name">{product.name}</h2>
            <p className="product-description">{product.description}</p>
            {product.gallery.length > 1 && (
              <div className="product-gallery">
                {product.gallery.slice(1).map((url, idx) => (
                  <img
                    key={idx}
                    src={url}
                    alt={`Product ${idx}`}
                    className="product-image"
                  />
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </li>
  );
};


export default Housing;
