import './App.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LuxusInterior from './components/home';
import About from './components/about';
import CompanyInfo from './components/contact';
import Housing from './components/housing';
import CommercialList from './components/commercial';
import OfficeList from './components/office';
import AddProduct from './admin/addProduct';
import LoginPage from './admin/login';

function App() {
  // Kiểm tra trạng thái đăng nhập từ sessionStorage
  const isAuthenticated = !!sessionStorage.getItem('isLoggedIn');

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<LuxusInterior />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<CompanyInfo />} />
          <Route path='/housing' element={<Housing />} />
          <Route path='/commercial' element={<CommercialList />} />
          <Route path='/office' element={<OfficeList />} />
          <Route path='/admin/login' element={<LoginPage />} />
          <Route
            path='/admin/add-product'
            element={isAuthenticated ? <AddProduct /> : <Navigate to="/admin/login" />}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
